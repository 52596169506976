export interface IState {
  isConnected: boolean
  connecting: boolean,
  publicKey: string;
  isLoading: boolean
}

export const emptyState: IState = {
  isConnected: false,
  connecting: true,
  publicKey: "",
  isLoading: true
};

export const Reducer = (state: IState, updatedProperties: Partial<IState>) => ({
  ...state,
  ...updatedProperties,
});
