import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from "react";
import { IParent } from "../../shared/types";
import { timeout } from "../../utils/utils";

import { emptyState, IState, Reducer } from "./reducer";

interface IContext extends IState {
  connect: () => void;
  disconnect: () => void;
}

const emptyContext: IContext = {
  ...emptyState,
  connect: () => null,
  disconnect: () => null,
};

const Context = createContext(emptyContext);

export const casperlabsHelper = (window as any).casperlabsHelper;

export const SignerProvider = ({ children }: IParent) => {
  const [state, dispatch] = useReducer(Reducer, emptyContext);

  // Check if user is already logged
  useEffect(() => {
    (async () => {
      try {
        await tryLogin();
        dispatch({ isLoading: false, connecting: false });
      
      } catch (e) {
        dispatch({ isLoading: false });
        dispatch({ connecting: false });
      }
    })();
  }, []);

  const tryLogin = async (): Promise<boolean> => {
    const isConnected = await casperlabsHelper.isConnected();

    if (!state.publicKey && isConnected) {
      const publicKey = await casperlabsHelper.getActivePublicKey();
      dispatch({ publicKey: publicKey, isConnected: true });
      dispatch({ connecting: false });

      return true;
    }
    return false;
  };

  const disconnect = () => {};

  const connect = async () => {
    if (true) {
      let success = false;

      try {
        await (window as any).casperlabsHelper.requestConnection();
        success = await tryLogin();
      } catch (e) {
        dispatch({ connecting: true });
        const pingTimeout = 1500;

        for (let i = 0; i < 60000 / pingTimeout; i++) {
          try {
            await timeout(pingTimeout);
            console.log("Pinging login...");
            success = await tryLogin();
          } catch {}

          if (success) break;
        }
        dispatch({ connecting: false });
        console.log("Connection error", e);
      }
    }
  };

  return (
    <Context.Provider
      value={{
        ...state,
        connect,
        disconnect,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useSignerProvider = () => useContext(Context);
